import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  IconButton, Box, StackDivider, Heading, HStack, Stack, Text, 
  Stat, StatLabel, StatNumber, Checkbox, Select,
  useBreakpointValue, useColorModeValue, 
} from '@chakra-ui/react';
import { GiNinjaStar } from 'react-icons/gi'
import { BsFillGrid3X3GapFill } from 'react-icons/bs'
import { PiListFill } from 'react-icons/pi'

// Custom
import { useMiners } from '../../common/components/miners/hooks/use-miners';
import { useIdentityInfo } from '../../common/components/stats/use-identity-info';
import MinersList from '../../common/components/miners/components/miners-list';
import { usePayouts } from '../../common/components/payouts/hooks/use-payouts';
import PayoutsList, { ListType, ListLayout } from '../../common/components/payouts/components/payouts-list';
import { usePoolInfo } from '../../common/components/stats/use-pool-info';
import MouseSlash from '../../common/components/mousetrail/mouse-slash';

/* eslint-disable import/no-default-export */
export default function DashboardPage() {
 
  const { identityId } = useParams();
  // get pool stats
  const { epoch, totalShurikenCount } = usePoolInfo()
  // Get the miners
  const { miners, minersStats, filterMiners, versions, sortMiners } = useMiners({identityId})
  // Get identity stats
  const identityStats = useIdentityInfo({identityId, epoch})
  // Get the payouts
  const { payouts, sortPayouts } = usePayouts({identityId})
  // which elements are visible
  const [visible, setVisible] = useState(['miners'])
  // layout type for miners list
  const [layout, setLayout] = useState(ListLayout.GRID)
  // check if we are on mobile
  const isMobile = useBreakpointValue({ base: true, md: false })

  const toggleUi = (element) => {
    if (visible.includes(element)) {
      setVisible(visible.filter((item) => item !== element))
    } else {
      setVisible([...visible, element])
    }
  }

  const toggleLayout = () => {
    setLayout(currentLayout => 
      currentLayout === ListLayout.GRID ? ListLayout.LIST : ListLayout.GRID
    )
  }

  // check if chexboxes are checked based on visible state
  const isChecked = element => visible.includes(element)

  // check if we show two elements
  const uiStyle = visible.length === 2 ? ListType.COMPACT : (isMobile ? ListType.COMPACT : ListType.EXPANDED)

  const getLayoutStyle = () => {
    if (isMobile) return '100%'
    return uiStyle === ListType.COMPACT ? '50%' : '100%'
  }

  return (
    <>
      <MouseSlash />
        <Box
          mt={8}
          py={{ base: 2 }}
          px={{ base: 4 }}
          border={{base: 'none', md: '1px'}}
          borderStyle="solid"
          borderRadius="md"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Stack mt={2} align="start" spacing={4}
            divider={<StackDivider borderColor='gray.200' />}
            direction={{ base: 'column', md: 'row' }}
          >
            <Stat>
              <StatLabel>Epoch</StatLabel>
              <StatNumber>{epoch}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Inactive: {minersStats.inactiveNinjas}</StatLabel>
              <StatNumber>{identityStats.myNinjaCount} Ninjas</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Your Hashrate</StatLabel>
              <StatNumber>
                {identityStats.myHashrate} it/s
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Found Shuriken</StatLabel>
              <StatNumber>
                <span style={{display: 'inline-block', margin: '0 5px -3px 0'}}><GiNinjaStar /></span>
                {identityStats.myShurikenCount}
              </StatNumber>
            </Stat>            
            <Stat>
              <StatLabel>Your Pool Share</StatLabel>
              <StatNumber>
                {(identityStats.myShurikenCount * 100 / totalShurikenCount).toFixed(2)} %
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Total Payouts</StatLabel>
              <StatNumber>coming soon</StatNumber>
            </Stat>
          </Stack>          
        </Box>

        <HStack mt={8} align={'center'} p={{ base: 4 }}
          divider={<StackDivider borderColor='gray.200' />}
        >
          <Checkbox size='lg' colorScheme='red' 
            onChange={() => toggleUi('miners')}
            isChecked={isChecked('miners')}>
            Miners
          </Checkbox>
          <Checkbox size='lg' colorScheme='red' 
            onChange={() => toggleUi('payouts')}
            isChecked={isChecked('payouts')}>
            Payouts
          </Checkbox>
        </HStack>
        
        <Stack alignItems={'flex-start'}
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 8 }}
          divider={<StackDivider borderColor='gray.200' />}
        >
          
          {/* List of Miners */}
          {isChecked('miners') &&
            <Box w={getLayoutStyle()} p={{base: 4}}>
              <Stack textAlign="center" spacing={{ base: 8 }} py={{ base: 10 }}>
                <Heading id='ninjas' fontWeight={600}>
                  <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                    All your Ninjas!
                  </Text>
                </Heading>
                <Stack gap={5} direction={{ base: 'column', md: 'row' }}>
                  <HStack>
                    <Text>Filter:</Text>
                    <Select 
                      placeholder="Version" 
                      onChange={(e) => filterMiners((e.target.value) ? {version: e.target.value} : {version: null})}>                    
                      {versions.map((version, index) => (
                        <option key={index} value={version}>{version}</option>
                      ))}
                    </Select>
                    {/* <Select 
                      placeholder="Status"
                      onChange={(e) => filterMiners((e.target.value) ? {online: (e.target.value === '1') ? true : false} : {online: null})}>
                        <option value={'1'}>online</option>
                        <option value={'0'}>offline</option>
                    </Select> */}
                  </HStack>
                  <HStack>
                    <Text>Sort:</Text>
                    <Select 
                      placeholder="Select Sorting" 
                      onChange={(e) => sortMiners(JSON.parse(e.target.value))}>
                      <option value={'{"name": 1}'}>Name A-Z</option>
                      <option value={'{"name": -1}'}>Name Z-A</option>
                      {/* <option value={'{"solutions": 1}'}>Shurikens</option> */}
                      <option value={'{"hashrate": 1}'}>It/sec ▲</option>
                      <option value={'{"hashrate": -1}'}>It/sec ▼</option>
                    </Select>
                    <IconButton colorScheme="red" size="sm" 
                      icon={(layout === ListLayout.LIST) ? <BsFillGrid3X3GapFill /> : <PiListFill />}
                      onClick={toggleLayout}
                    />
                  </HStack>
                </Stack>
              </Stack>
              {/* <Suspense fallback={<Spinner />}> */}
                <MinersList 
                  miners={miners}
                  layout={layout}
                  listType={uiStyle} 
                />
              {/* </Suspense> */}
            </Box>          
          }
          
          {/* List of Payouts */}
          {isChecked('payouts') &&
            <Box w={getLayoutStyle()}>
              <Stack textAlign="center" spacing={{ base: 8 }} py={{ base: 10 }}>
                <Heading id='payouts' fontWeight={600}>
                  <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                    Your Payouts!
                  </Text>
                </Heading>
                <HStack gap={5}>
                  <Text>Sort:</Text>
                  <Select 
                    placeholder="Select Sorting" 
                    onChange={(e) => sortPayouts(JSON.parse(e.target.value))}>
                    <option value={'{"status": 1}'}>Status</option>
                    <option value={'{"amount": 1}'}>Amount</option>
                    <option value={'{"payout_at": 1}'}>Date</option>
                    <option value={'{"epoch": 1}'}>Epoch</option>
                  </Select>
                </HStack>
              </Stack>
              {/* <Suspense fallback={<Spinner />}> */}
                <PayoutsList 
                  payouts={payouts} 
                  listType={uiStyle}
                />
              {/* </Suspense> */}
            </Box>
          }

        </Stack>      
    </>
  );
}
