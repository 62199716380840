import React from 'react'
import { 
  useColorModeValue, Container, Stack, Flex, Image, Button, Card, Text, CardHeader, 
  CardBody, Box, Heading, createIcon, Accordion, AccordionItem, 
  AccordionButton, AccordionPanel, AccordionIcon, OrderedList, ListItem, Fade 
} from '@chakra-ui/react'
import { GiRunningNinja } from 'react-icons/gi'
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { InView } from 'react-intersection-observer';
import MouseSlash from '../../common/components/mousetrail/mouse-slash';
import { routes } from '../../routes'
import { RiDashboard2Fill } from "react-icons/ri";
import { CodeCopyClipboard } from '../../common/components/code-copy-clipboard'
import PoolInfo from '../../common/components/stats/pool-info';

/* eslint-disable import/no-default-export */
export default function LandingPage() {  
  // const navigate = useNavigate()
  
  return (
    <>
    <MouseSlash />

    {/* <Suspense fallback={<Spinner />}> */}

    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bgGradient: 'linear(to-l, #960404, #D72525)',
                zIndex: -1,
              }}>
              Qubic AI Training
            </Text>
            <br />
            <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
              made easy!
            </Text>
          </Heading>
          <Text>
            Sneak into the shadows of Qubic, armed with our ninja-grade toolkit, and carve your path through 
            the AI training wilderness. Collect shurikens and get your share of the pool revenues paid out in QUBIC each week. 
            It's not just a mission &ndash; it's your destiny to join the ranks of the qubic ninja clan. 
          </Text>
          <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>
            <Button as="a" href={routes.start}
              rounded={'full'} size={'lg'} px={6} color={'white'}              
              colorScheme={'red'} bgGradient={'linear(to-l, #960404, #D72525)'}
              _hover={{ bg: 'red.500' }}
              leftIcon={<RiDashboard2Fill color={'white'} />}>
              Dashboard
            </Button>
            <Button as="a" href="#how-it-works"
              rounded={'full'} size={'lg'} px={6}
              leftIcon={<ShurikensIcon color={'gray.300'} />}>
              How It Works
            </Button>
            <Button as="a" href="#install"
              rounded={'full'} size={'lg'} px={6}
              leftIcon={<GiRunningNinja color={'gray.300'} />}>
              Install Ninja
            </Button>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Box
            position={'relative'}
            height={'400px'}
            width={'full'}
            overflow={'hidden'}>
            <Image alt={'Qubic Ninja'} h={'100%'} src={'/ninja.svg'} mx={'auto'} display={'block'} />
          </Box>
        </Flex>
      </Stack>
    </Container>

    <Box
      mt={8} m={{ base: 4, md: 8}}
      py={{ base: 2 }} px={{ base: 4 }} pb={{ base: 4 }}
      border={1} borderStyle="solid" borderRadius="md"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
    >
      <PoolInfo /> 
    </Box>

    <Box mt={20} id="how-it-works">
      <Stack spacing={4} as={Container} maxW={'7xl'} textAlign={'center'}>
        <Heading fontWeight={600}>
                <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                How It Works: Maximizing impact with every hash
                </Text>
              </Heading>
        <Text fontSize={{ base: 'sm', sm: 'lg' }}>
          Harness the power of our PPLNS (Pay per last N shares) Qubic mining approach
        </Text>
      </Stack>

      <Container mt={12} maxW={'7xl'}>
        <Flex direction="row" flexWrap="wrap" justify="center" gridGap={6}>
          <Card 
            maxW={{ base: 'full', md: '300px' }}
            w={'full'}
            borderRadius="lg"
            overflow="hidden"
          >
            <CardHeader>

              <InView rootMargin="-200px" triggerOnce={true}>
                {({inView, ref}) =>
                  <Fade in={inView}>
                    <Image ref={ref} src={'/network-icon.svg'} alt="cpu mining" height={'250px'} m={'auto'} display={'block'} />
                  </Fade>
                }
              </InView >

              <Heading mt={2} fontSize={'2xl'} fontWeight={500}>
                <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                  Empowering every contribution
                </Text>
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                In our Qubic mining quest, we recognize the valor of every warrior, regardless of the size of their arsenal. Aigarth 
                values every bit of computing power, leading us to our democratic Shuriken rewards system. Each Shuriken signifies 
                your share of the pool's revenue, rewarding every computational effort made. It's how we honor and recognize each miner's contribution.
              </Text>              
            </CardBody>
          </Card>

          <Card 
            maxW={{ base: 'full', md: '300px' }}
            w={'full'}
            borderRadius="lg"
            overflow="hidden"
          >
            <CardHeader>
              <InView rootMargin="-200px" triggerOnce={true}>
                {({inView, ref}) =>
                  <Fade in={inView}>
                    <Image ref={ref} src={'/cpu-icon.svg'} alt="cpu mining" height={'250px'} m={'auto'} display={'block'} />
                  </Fade>
                }
              </InView >
              <Heading mt={2} fontSize={'2xl'} fontWeight={500}>
                <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                  A focused approach on CPU-only mining
                </Text>
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Our battlefield is chosen, and it lies within the dominion of CPU mining. This strategy allows us to refine our 
                tools and tactics, focusing on a top-notch mining experience accessible to a broader range of participants. By 
                concentrating our efforts on CPU mining, we stay agile and inclusive, welcoming all who wish to join our ranks, 
                regardless of their equipment.
              </Text>              
            </CardBody>
          </Card>
          <Card 
            maxW={{ base: 'full', md: '300px' }}
            w={'full'}
            borderRadius="lg"
            overflow="hidden"
          >
            <CardHeader>
              <InView rootMargin="-200px" triggerOnce={true}>
                {({inView, ref}) =>
                  <Fade in={inView}>
                    <Image ref={ref} src={'/payout-icon.svg'} alt="cpu mining" height={'250px'} m={'auto'} display={'block'} />
                  </Fade>
                }
              </InView >
              <Heading mt={2} fontSize={'2xl'} fontWeight={500}>
                <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                  The Epochs of Qubic &ndash; A Structured Campaign
                </Text>
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Our mining operations beat with the rhythmic drum of Qubic epochs, each a week-long campaign from Wednesday to 
                Wednesday, starting and ending at 12 UTC. This structured approach not only provides a steady rhythm to our mining 
                efforts but also aligns perfectly with the Qubic network's payout schedule, ensuring that rewards are distributed 
                predictably one week after an epoch's conclusion.
              </Text>              
            </CardBody>
          </Card>
        </Flex>
      </Container>
    </Box>   

    <Box mt={20} id="install">
      <Stack spacing={4} as={Container} maxW={{ base: 'full', md: '950px' }} textAlign={'center'}>
        <Heading fontWeight={600}>
          <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
            Ninja Gear: Quick Start Mining
          </Text>
        </Heading>
        <Text fontSize={{ base: 'sm', sm: 'lg' }}>
          Ninja Gear is your quick-start guide to unlocking the power of your CPU in the quest for digital treasure.<br /> 
          Get ready to join the ranks of Qubic miners with a few simple steps.
        </Text>
      </Stack>  

      <Container mt={12} maxW={'7xl'}>
        <Flex direction="row" flexWrap="wrap" justify="center" gridGap={6}>
          <Card 
                maxW={{ base: 'full', md: '950px' }}
                w={'full'}
                borderRadius="lg"
                overflow="hidden"
              >
            <CardBody>
              <Text>
                To run the Qubic Ninja Gear client you can use this streamlined installation guide. 
                Please consider adapting the commands to suit your directory preferences. Note that all 
                commands must be executed as the root user, or you should precede them with the sudo 
                command for proper authorization.
              </Text>              
                
                <CodeCopyClipboard>
                  wget -O ninja-gear-install.sh https://dl.qubic.ninja/ninja-gear-install.sh
                </CodeCopyClipboard>
                <CodeCopyClipboard>
                  chmod u+x ninja-gear-install.sh
                </CodeCopyClipboard>
                <CodeCopyClipboard>
                  ./ninja-gear-install.sh 2 NRJISP...XSFACFYL
                </CodeCopyClipboard>
            </CardBody>
          </Card>
        </Flex>
      </Container>
    </Box>


    <Box mt={20} id="faq">
      <Stack spacing={4} as={Container} maxW={{ base: 'full', md: '950px' }} textAlign={'center'}>
        <Heading fontWeight={600}>
                <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                For the Curious and the Brave (FAQ)
                </Text>
              </Heading>
        <Text fontSize={{ base: 'sm', sm: 'lg' }}>
          Dive deeper into the mechanics of SMPPS and our mining ethos in our FAQ section. Here, you'll find everything from strategic 
          advice to technical support, all designed to enhance your mining prowess.       
        </Text>
      </Stack>   

      <Container mt={12} maxW={'7xl'}>
        <Flex direction="row" flexWrap="wrap" justify="center" gridGap={6}>
          <Card 
                maxW={{ base: 'full', md: '950px' }}
                w={'full'}
                borderRadius="lg"
                overflow="hidden"
              >

            <Accordion mt={4} allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={600}>
                      What are Shurikens in the context of Qubic mining?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Shurikens are akin to the solutions found in traditional Qubic mining but are designed to be more accessible for every 
                  miner, regardless of their computational power. Simply put: it is much easier to find shurikens than solutions and serve
                  as a metric to gauge and reward your mining efforts. While each Shuriken represents a potential contribution to the pool's
                  computational power, occasionally, a Shuriken may also be a valid solution for the Qubic network. This dual nature allows 
                  us to reward consistent mining efforts and contribute meaningful solutions to the network.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={600}>
                      How does the PPLNS reward system work?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  PPLNS, or Pay per last N shares, is our chosen reward system that ensures fair distribution of earnings based on 
                  your contributed mining power. It means that your reward is calculated based on the number of Shurikens (shares) you've 
                  earned during one epoch relative to the total shares in the pool found in the same epoch, ensuring you never earn less 
                  than what you've contributed. This system guarantees that the rewards distributed never exceed the pool's earnings, 
                  maintaining a sustainable mining environment.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={600}>
                      Can I mine with a GPU?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  No, because currently, our focus is on CPU mining. We've optimized our mining process for CPU use to ensure accessibility 
                  and efficiency for a broader range of participants. Our tools and strategies are tailored for CPU mining, making it 
                  unnecessary to have a GPU for mining in our pool.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={600}>
                      What is an epoch, and how does it affect my mining?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  An epoch in Qubic mining is a fixed period, running from Wednesday to Wednesday, 12 UTC to 12 UTC. However, you can start mining whenever you want.
                  This weekly cycle dictates the rhythm of our mining operations and revenue distribution. Your mining efforts within an epoch accumulate 
                  Shurikens, which determine your share of the pool's payout. Payouts are then distributed a week after an epoch concludes, 
                  allowing for seamless transition between mining periods and a predictable reward schedule.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={600}>
                      How do I start mining with Qubic Ninja?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                To begin mining with us, follow these simple steps:
                <OrderedList>
                  <ListItem>Configure Your Miner: Download and set up our CPU miner with your QUBIC ID</ListItem>
                  <ListItem>Start Mining: Align your mining operations with the epoch schedule to maximize your earnings.</ListItem>
                  <ListItem>Earn Shurikens: Your mining power translates into Shurikens, marking your share of the pool's revenue.</ListItem>
                  <ListItem>Log In: See your performance with your QUBIC ID on our platform.</ListItem>
                  <ListItem>Receive Payouts: Rewards are distributed a week after each epoch, based on your accumulated Shurikens.</ListItem>
                </OrderedList>

                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={600}>
                    What happens if a Shuriken is also a valid solution for the Qubic network?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Only with our ninja miner is it possible to find shurikens at all. When a Shuriken found by our pool miners turns out to 
                  be a valid solution for one of the Qubic computors run by the pool, we submit this valuable resource to the Qubic network. 
                  This not only contributes to the network's strength but also enhances the rewards for the entire pool. The more Shurikens
                  we find as a pool, the higher the chances of finding real solutions, the more computors we can run as a pool, the higher the
                  rewards for the pool. This underscores the importance of every miner's effort, as even smaller contributions can lead to 
                  significant discoveries.
                </AccordionPanel>
              </AccordionItem>

            </Accordion>
          </Card>
        </Flex>
      </Container>
    </Box>

<Box
    bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.700', 'gray.200')}
    mt={20}>
    <Container
      as={Stack}
      maxW={'6xl'}
      py={4}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ base: 'center', md: 'space-between' }}
      align={{ base: 'center', md: 'center' }}>
      <Image src={useColorModeValue('/logo-quad.svg', '/logo-quad.svg')} alt="qubic.ninja Logo" height={'40px'} />
      <Text>© 2024 Qubic Ninja. All rights reserved</Text>
      <Stack direction={'row'} spacing={6}>
        <Button leftIcon={<FaDiscord />} href={'#'}> Discord </Button>
        <Button leftIcon={<FaTwitter />}  href={'https://twitter.com/QubicNinja'}> Twitter </Button>

      </Stack>
    </Container>
  </Box>

{/* </Suspense> */}
    </>
  )
}


const ShurikensIcon = createIcon({
  displayName: 'ShurikensIcon',
  viewBox: '0 0 512 512',
  d: `M328.8759766,132.5421448C371.3331909,201.1544342,478.1032104,73.603653,499.8647461,16.565836c61.182312,226.6875153-128.5315552,308.9147949-135.4263916,328.8926086c7.2550049,69.3253784,51.509552,118.1947937,131.3958435,156.3851624c-99.1514587,29.8260803-238.6083679-6.4488525-316.8009644-121.7224731C143.0673523,327.0992737,30.8019505,430.3282166,13.780839,496.2008667c-34.3766518-113.4429626,2.4183283-172.5073853,35.5939369-230.9724121c38.4000206-67.671936,102.2507477-75.3491211,97.4141083-117.2668152C140.0868835,89.8777008,73.1363449,30.5032635,14.5869474,13.3414001C131.6380463-32.9872322,278.9995422,51.9401283,328.8759766,132.5421448z M303.8504028,263.709137c0-40.709198-44.3727722-66.2905884-79.6792755-45.9359894s-35.3065033,71.5173798,0.0000153,91.8719635C259.4776611,329.9997253,303.8504028,304.418335,303.8504028,263.709137z`
})

// const PlayIcon = createIcon({
//   displayName: 'PlayIcon',
//   viewBox: '0 0 58 58',
//   d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
// })